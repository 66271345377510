body {
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: oldlace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  margin: 30px 10%;
}

div.ui.header::before {
  content: none;
}

.ui.placeholder .header:not(:first-child):before,
.ui.placeholder .image:not(:first-child):before,
.ui.placeholder .paragraph:not(:first-child):before {
    content: none
};

